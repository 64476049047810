<template>
  <div class="auth-wrapper auth-v2 slidercontainer">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex align-items-center slideritem">
        <div class="banneritem d-none">
          <div class="logoitem">
            <img src="@/assets/images/premium-tka.svg" />
          </div>
        </div>
        <b-carousel img-height="480" controls indicators>
          <b-carousel-slide img-src="@/assets/images/slider-1.png" />
          <b-carousel-slide img-src="@/assets/images/slider-2.png" />
          <b-carousel-slide img-src="@/assets/images/slider-3.png" />
          <b-carousel-slide img-src="@/assets/images/slider-4.png" />
        </b-carousel>
      </b-col>
      <b-col
        lg="4"
        class="d-flex flex-column justify-content-center auth-bg contentitem"
       style="background-color:#121415" >
        <div class="logoimg">
          <img
            src="@/assets/images/premium-tka.svg"
            width="320"
            class="d-none"
            alt=""
          />

        </div>
        <div
          class="panel-mini panel-devicerequest card"
          style="border: none !important; box-shadow:none; background-color:#121415; border:1px solid #494946;"
        >
         <img
            src="@/assets/images/premium-tka-white.svg"
            width="320"
            class="mt-2"
            alt=""
          />
          <div class="card-body" style="background-color: #121415 !important;">
   
           <b-card-title class="mb-0 font-weight-bold" title-tag="h2">
            TKA Bilişim CRM
          </b-card-title>
          <div class="h4 text-white mb-1">Customer Relationship Management</div>
          <b-card-text class="mb-2">
            Avrupa Standartlarına Uygun Türkiye'nin En Donanımlı Bilgisayar
            Tamir Laboratuvarları
          </b-card-text>

          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Parola</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Oturum Aç
              </b-button>
            </b-form>
          </validation-observer>

          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Logo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import api from "@/service/api";
import utils from "@/service/utils";

export default {
  components: {
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
    };
  },
  created() {
    utils.setTitle("Oturum Aç");
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      this.sideImg =
        "https://tkabilisim.com.tr/assets/uploads/tka-w-arac-3.jpg";
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg =
          "https://tkabilisim.com.tr/assets/uploads/tka-w-arac-3.jpg";
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          let data = { email: this.userEmail, password: this.password };
          api.action("login", data).then((q) => {
            if (q.success == false) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Bilgi",
                  icon: "AlertCircleIcon",
                  variant: "warning",
                  text: q.message,
                },
              });
              return;
            }
            let data = q.data;
            let user = q.data.user;
            user.ability = [{ action: "manage", subject: "all" }];
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("accessToken", user.access_token);
            localStorage.setItem(
              "definitions",
              JSON.stringify(data.definitions)
            );
            localStorage.setItem("definition_city", JSON.stringify(data.city));
            localStorage.setItem("users", JSON.stringify(data.users));
            localStorage.setItem(
              "definition_district",
              JSON.stringify(data.district)
            );

            localStorage.setItem("offices", JSON.stringify(data.offices));
            localStorage.setItem(
              "delivery_status",
              JSON.stringify(data.delivery_status)
            );
            localStorage.setItem(
              "device_status",
              JSON.stringify(data.device_status)
            );

            this.$ability.update(user.ability);
            this.$router.push("/dashboard");
          });
          return;

          /*
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
          */
        }
      });
    },
  },
};
</script>


<style lang="scss" >
@import "@core/scss/vue/pages/page-auth.scss";
.carousel-item img {
  height: 100vh !important ;
}
@media screen and (max-width: 576px) {
  .logoimg {
    text-align: center;
    img {
      margin: 0px;
      margin: auto;
    }
  }
}
.bg-white {
  background-color: #fff !important;
}
.slidercontainer
{
  background: #121414;
}
</style>